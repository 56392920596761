<template>
  <div id="printArea">
    <CRow>
      <CCol sm="12">
        <CCard>
          <CRow>
            <CCol sm="2">
              <h4>Generated bill list</h4>
            </CCol>
            <CCol sm="10">
              <form v-on:submit.prevent="filter()">
                <CRow>
                  <CCol sm="2" class="pr-0">
                    <el-date-picker
                        @change="getBatchNo"
                        required
                        class="w-100"
                        v-model="params.month"
                        type="month"
                        placeholder="Select Month"
                        :format="$monthPickerFormat"
                        value-format="yyyy-MM"
                    />
                  </CCol>
                  <CCol sm="2" class="pr-0">
                    <select class="form-control" v-model="params.batch_no">
                      <option value="">All</option>
                      <option :value="batchNoList" v-for="batchNoList in batchNoLists">{{ batchNoList.batch_no }}
                      </option>
                    </select>
                  </CCol>
                  <CCol sm="2">
                    <button type="submit" class="btn btn-success w-100">Submit</button>
                  </CCol>
                  <CCol v-if="checkUsrPermissions(['Bill_all_delete'])" sm="2">
                    <button type="button" @click="deleteReading()" class="btn btn-danger w-100">Delete All</button>
                  </CCol>
                  <CCol sm="2">
                    <button type="button" @click="printMultiBill()" class="btn btn-primary w-100">Multi Print</button>
                  </CCol>
                  <CCol v-if="checkUsrPermissions(['Bill_transfer_to_SQL'])" sm="2">
                    <button type="button" @click="transferToSQL()" class="btn btn-primary w-100">Transfer to SQL</button>
                  </CCol>
                </CRow>
              </form>
            </CCol>
          </CRow>
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th class="font-weight-bold">SL</th>
                <th class="font-weight-bold">Account No</th>
                <th class="font-weight-bold">Bill No.</th>
                <th class="font-weight-bold">Issue Date</th>
                <th class="font-weight-bold">Due Date</th>
                <th class="font-weight-bold">PrevRead</th>
                <th class="font-weight-bold">PrevDate</th>
                <th class="font-weight-bold">PresRead</th>
                <th class="font-weight-bold">PresDate</th>
                <th class="font-weight-bold">Days</th>
                <th class="font-weight-bold">Consum</th>
                <th class="font-weight-bold">WaterBill</th>
                <th class="font-weight-bold">Excise</th>
                <th class="font-weight-bold">Total</th>
<!--                <th class="font-weight-bold">H/S</th>-->
                <th class="font-weight-bold" v-if="checkUsrPermissions(['Bill_all', 'Bill_download'])">Actions</th>
              </tr>
              </thead>

              <tbody v-if="generated_bills.length">
              <tr
                  v-for="(bill, index) in generated_bills"
                  :key="bill.id"
              >
                <td scope="row">{{ meta.from + index }}</td>
                <td class="font-weight-bold">
                  <router-link :to="`/consumers/${bill.customer_account_id}`">
                    {{ bill.consumer_account_number }}
                  </router-link>
                </td>
                <td>{{ bill.bill_number }}</td>
                <td>{{ bill.issue_date | dateFormat }}</td>
                <td>{{ bill.due_date | dateFormat }}</td>
                <td>{{ bill.previous_reading }}</td>
                <td>{{ bill.previous_reading_date | dateFormat }}</td>
                <td>{{ bill.present_reading }}</td>
                <td>{{ bill.present_reading_date | dateFormat }}</td>
                <td>{{ bill.total_days }}</td>
                <td>{{ bill.used_unit }}</td>
                <td>{{ bill.min_amount }}</td>
                <td>{{ bill.vat }}</td>
                <td>{{ bill.total_amount }}</td>
<!--                <td>{{ bill.customer_account.house_serial }}</td>-->
                <td>
                  <a
                      href="javascript:void(0)"
                      class="download-bill btn btn-primary btn-sm"
                      @click="downloadBill(bill)"
                  >Print</a
                  >
                </td>
              </tr>
              </tbody>
              <tbody v-else>
              <tr>
                <td colspan="20">
                  <h5 class="text-center">Data Not Available.</h5>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import axios from "axios";

export default {
  name: "ReadingGenerate",
  data: () => {
    return {
      batchNoLists: [],
      params: {
        batch_no: '',
        month: moment().clone().subtract(1, 'months').format('YYYY-MM'),
        page: 1,
        isStore: false
      },
      isMobile: Boolean,
      isMobileOnly: Boolean,
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Bill Generate",
        path: "/bill/generate",
        isActive: false
      }, {text: "Generated bill list", path: "", isActive: true}]
    };
  },

  computed: {
    ...mapGetters("Reading", [
      "generated_bills",
      "rows",
      "perPage",
      "meta"
    ]),
  },
  methods: {
    fetchGenerateMonth() {
      axios.get(`/admin/fetch-generate-month/bill`).then(({data}) => {
        if (data.status) {
          const generatedMonth = data.data.generate_year + '-' + data.data.generate_month
          this.params.month = moment(generatedMonth).format('YYYY-MM');
        }

        this.getBatchNo()
        this.params.meterId = this.$route.params.id;
        this.params.batch_no_id = this.params.batch_no ? this.params.batch_no.id : ''
        this.$store.dispatch("Reading/getGeneratedBills", this.params);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        this.$store.dispatch("Settings/loading", false);
      })
    },
    getBatchNo() {
      axios.get(`/admin/batch-no/lists`, {params: {month: this.params.month}}).then((res) => {
        this.batchNoLists = res.data;
      }).catch((err) => {
        console.log(err);
      });
    },
    downloadBill(bill) {
      this.$store.dispatch("Bill/downloadBill", bill);
    },
    filter() {
      this.params.batch_no_id = this.params.batch_no ? this.params.batch_no.id : ''
      this.$store.dispatch("Reading/getGeneratedBills", this.params);
    },
    deleteReading() {
      const formattedMonth = moment(this.params.month).format('MMMM-YYYY')
      this.$confirm({
        message: `Are you sure, want to delete ${formattedMonth} month bills data ?`,
        button: {
          no: "No",
          yes: "Yes, Delete",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("Settings/loading", true);
            axios.get(`/admin/all-bills/delete`, {params: {month: this.params.month}}).then(({data}) => {
              if (data.status == 'error') {
                return this.$toastr.e(
                    data.status,
                    data.message,
                    data.title
                );
              }
              this.$toastr.s(
                  data.status,
                  data.message,
                  data.title
              );
              this.$store.dispatch("Reading/getGeneratedBills", this.params);
            }).catch((err) => {
              console.log(err);
            }).finally(() => {
              this.$store.dispatch("Settings/loading", false);
            });
          }
        },
      });
    },
    printMultiBill() {
      this.$store.dispatch("Settings/loading", true);
      let queryParams = `month=${this.params.month}&batch_no_id=${this.params.batch_no ? this.params.batch_no.id : ''}`;
      axios
          .get(`/admin/bill-generates/multi-bill-download?${queryParams}`, {
            responseType: "arraybuffer",
          })
          .then((res) => {
            let blob = new Blob([res.data], {
              type: "application/pdf;charset=utf-8",
            });
            const batch_no = this.params.batch_no ? `_${this.params.batch_no.batch_no}` : ''
            var filename = this.params.month + batch_no + "_bill.pdf";
            saveAs(blob, filename);
          })
          .catch(() => {
            // this.$toastr("error", "Something went wrong", "Error!");
          }).finally(() => {
        this.$store.dispatch("Settings/loading", false);
      });
    },

    transferToSQL() {
      const formattedMonth = moment(this.params.month).format('MMMM-YYYY')
      this.$confirm({
        message: `Are you sure, want to transfer ${formattedMonth} month bills data to SQL server?`,
        button: {
          no: "No",
          yes: "Yes, Transfer",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("Settings/loading", true);
            let queryParams = `month=${this.params.month}&batch_no_id=${this.params.batch_no ? this.params.batch_no.id : ''}`;
            axios
                .get(`/admin/bill-generates/transfer-sql?${queryParams}`)
                .then(({data}) => {
                  if (data.status == 'error') {
                    return this.$toastr.e(
                        data.status,
                        data.message,
                        data.title
                    );
                  }
                  this.$toastr.s(
                      data.status,
                      data.message,
                      data.title
                  );
                })
                .catch(() => {
                  return this.$toastr.e(
                      "error",
                      "Something went wrong",
                      "Error!"
                  );
                }).finally(() => {
              this.$store.dispatch("Settings/loading", false);
            })
          }
        },
      });
    },
  },
  mounted() {
    this.fetchGenerateMonth()
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
    this.params.month = this.$route.query.month || moment().clone().subtract(1, 'months').format('YYYY-MM')
  },
};
</script>
<style scoped>
.card {
  border: none;
  padding: 15px 15px 0;
}

</style>

<style lang="scss" scoped>
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }

  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }

    tbody {
      font-size: 13px;

      tr {
        color: #000;

        td {
          padding: 0.75rem 0 0.75rem 0.75rem;

          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
